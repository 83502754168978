<template>
  <div>
    <div class="container">
      <div class="example1" v-if="floor === '一层'">
        <div class="blk">
          <div class="bed1 A00"></div>
          <div>空闲</div>
        </div>
        <div class="blk">
          <div class="bed1 A01"></div>
          <div>占用</div>
        </div>
      </div>
      <div class="example3" v-if="floor === '三层'">
        <div class="blk">
          <div class="bed1 A00"></div>
          <div>空闲</div>
        </div>
        <div class="blk">
          <div class="bed1 A01"></div>
          <div>全部占用</div>
        </div>
        <div class="blk">
          <div class="bed1 A02">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
          <div>下铺占用</div>
        </div>
        <div class="blk">
          <div class="bed1 A03">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
          <div>上铺占用</div>
        </div>
      </div>
      <div class="floor-img">
        <div class="floor-group">
          <el-radio-group v-model="floor" size="medium">
            <!-- <el-radio-button
            v-for="item in floorList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</el-radio-button
          > -->
            <el-radio-button label="一层"></el-radio-button>
            <el-radio-button label="二层"></el-radio-button>
            <el-radio-button label="三层"></el-radio-button>
            <el-radio-button label="四层"></el-radio-button>
          </el-radio-group>
        </div>
        <img class="room" width="1200" :src="imgSrc" alt="" />
        <div class="mock1" v-if="floor === '一层'">
          <div class="room1"></div>
          <div class="room2"></div>
          <div class="room3"></div>
          <div class="room4"></div>
          <div class="room5"></div>
        </div>
        <div class="mock3" v-if="floor === '三层'">
          <div class="bed A1"></div>
          <div class="bed A2"></div>
          <div class="bed A3">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
          <div class="bed A4">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
          <div class="bed A5"></div>
          <div class="bed A6"></div>
          <div class="bed A7"></div>
          <div class="bed A8"></div>
          <div class="bed A9"></div>
          <div class="bed A10"></div>
          <div class="bed A11"></div>
          <div class="bed A12"></div>
          <div class="bed A13"></div>
          <div class="bed A14"></div>
          <div class="bed A15"></div>
          <div class="bed A16"></div>
          <div class="bed A17"></div>
          <div class="bed A18"></div>
          <div class="bed A19"></div>
          <div class="bed A20"></div>
          <div class="bed A21"></div>
          <div class="bed A22"></div>
          <div class="bed A23"></div>
          <div class="bed A24"></div>
          <div class="bed A25"></div>
          <div class="bed A26">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
          <div class="bed A27"></div>
          <div class="bed A28"></div>
          <div class="bed A29"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      floor: '一层',
      floorList: [
        { id: 1, name: '一层' },
        { id: 2, name: '二层' },
        { id: 3, name: '三层' },
        { id: 4, name: '四层' },
      ],
      imgSrc: require('../../../assets/1.jpg'),
    }
  },
  created() {},
  methods: {},
  watch: {
    floor(newVal) {
      console.log(newVal)
      if (newVal === '一层') {
        this.imgSrc = require('../../../assets/1.jpg')
      } else if (newVal === '二层') {
        this.imgSrc = require('../../../assets/2.jpg')
      } else if (newVal === '三层') {
        this.imgSrc = require('../../../assets/3.jpg')
      } else if (newVal === '四层') {
        this.imgSrc = require('../../../assets/4.jpg')
      }
    },
  },
}
</script>

<style scoped>
.container {
  width: 1200px;
}
.room {
  position: absolute;
}
.mock3 {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mock1 {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
}
.bed {
  width: 21px;
  height: 46px;
  opacity: 0.5;
}
.A1 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 446px;
}
.A2 {
  background-color: rgb(227, 64, 64);

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 446px;
}
.A3 {
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  top: 285px;
  left: 486px;
}
.A3 .top {
  width: 100%;
  height: 50%;
  background: rgb(227, 64, 64);
}
.A3 .bottom {
  width: 100%;
  height: 50%;
  background: aquamarine;
}
.A4 {
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  top: 285px;
  left: 554px;
}
.A4 .top {
  width: 100%;
  height: 50%;
  background: aquamarine;
}
.A4 .bottom {
  width: 100%;
  height: 50%;
  background: rgb(227, 64, 64);
}
.A5 {
  background-color: rgb(227, 64, 64);

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 363px;
}

.A6 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 389px;
}
.A7 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 305px;
}
.A8 {
  background-color: rgb(227, 64, 64);

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 157px;
}
.A9 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 485px;
}
.A10 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 554px;
}
.A11 {
  background-color: rgb(227, 64, 64);

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 928px;
}
.A12 {
  background-color: rgb(227, 64, 64);

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 872px;
}
.A13 {
  background-color: rgb(227, 64, 64);

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 845px;
}
.A14 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 780px;
}
.A15 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 647px;
}
.A16 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 340px;
  left: 581px;
}
.A17 {
  background-color: aquamarine;
  width: 15px;
  height: 46px;
  z-index: 10;
  position: absolute;
  top: 340px;
  left: 203px;
}
.A29 {
  background-color: rgb(227, 64, 64);
  width: 15px;
  height: 46px;
  z-index: 10;
  position: absolute;
  top: 284px;
  left: 202px;
}
.A18 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 389px;
}
.A19 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 363px;
}
.A20 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 780px;
}
.A21 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 845px;
}
.A22 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 871px;
}
.A23 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 580px;
}
.A24 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 647px;
}
.A25 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 647px;
}
.A26 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 929px;
}
.A26 .top {
  width: 100%;
  height: 50%;
  background: aquamarine;
}
.A26 .bottom {
  width: 100%;
  height: 50%;
  background: rgb(227, 64, 64);
}
.A27 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 156px;
}
.A28 {
  background-color: aquamarine;

  z-index: 10;
  position: absolute;
  top: 285px;
  left: 303px;
}

.floor-group {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 130;
}
.floor-group >>> .el-radio-button {
  display: block;
}
.example1 {
  position: absolute;
  display: flex;
  top: 20px;
  left: 20px;
  z-index: 100;
}
.room1 {
  width: 70px;
  height: 197px;
  opacity: 0.4;
  z-index: 10;
  position: absolute;
  background-color: rgb(227, 64, 64);

  top: 264px;
  left: 165px;
}
.room2 {
  width: 43px;
  height: 197px;
  opacity: 0.4;
  z-index: 10;
  position: absolute;
  background-color: rgb(227, 64, 64);

  top: 264px;
  left: 263px;
}
.room3 {
  width: 166px;
  height: 197px;
  opacity: 0.4;
  z-index: 10;
  position: absolute;
  background-color: aquamarine;

  top: 264px;
  left: 314px;
}
.room4 {
  width: 106px;
  height: 197px;
  opacity: 0.4;
  z-index: 10;
  position: absolute;
  background-color: rgb(227, 64, 64);

  top: 264px;
  left: 497px;
}
.room5 {
  width: 345px;
  height: 197px;
  opacity: 0.4;
  z-index: 10;
  position: absolute;
  background-color: aquamarine;

  top: 264px;
  left: 616px;
}
.example3 {
  position: absolute;
  display: flex;
  top: 20px;
  left: 20px;
  z-index: 100;
}
.blk {
  display: flex;
}
.bed1 {
  width: 12px;
  height: 24px;
  opacity: 0.5;
  margin: 0 10px;
}
.A00 {
  background-color: aquamarine;
}
.A01 {
  background-color: rgb(227, 64, 64);
}
.A02 .top {
  width: 100%;
  height: 50%;
  background: aquamarine;
}
.A02 .bottom {
  width: 100%;
  height: 50%;
  background: rgb(227, 64, 64);
}
.A03 .bottom {
  width: 100%;
  height: 50%;
  background: aquamarine;
}
.A03 .top {
  width: 100%;
  height: 50%;
  background: rgb(227, 64, 64);
}
</style>
